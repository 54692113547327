
import { Vue, Component, Watch } from "vue-property-decorator";
import liff from "@line/liff";

@Component
export default class PrivacyPolicy extends Vue {
  accept = false
  get merckUserResponse() {
    return this.$store.state.merckUserResponse
  }

  acceptPrivacyPolicy() {
    this.$store.dispatch('updateMerckUser', {
      merck_user: {accept_privacy_policy: true}
    })
  }

  declinePrivacyPolicy() {
    this.$store.dispatch('updateMerckUser', {
      merck_user: {accept_privacy_policy: false}
    })
  }

  @Watch('merckUserResponse')
  merckUserResponseChanged(valueChange) {
    if (valueChange && valueChange.code === 200) {
      if (liff.isInClient()) {
        liff.closeWindow()
      } else {
        // TODO: DO something when accept or decline on web
      }
    }
  }
}
