
import {Vue, Component, Prop, Mixins, Watch} from "vue-property-decorator";
import GlobalMixin from "mixin/global_mixin.vue";
import liff from "@line/liff";

@Component
export default class JournalCorner extends Mixins(GlobalMixin) {
  @Prop() journalCornerType: string

  filter = {
    search: null
  }

  get journalCorners() {
    return this.$store.state.journalCorners
  }
  get registerResponse() {
    return this.$store.state.registerResponse
  }
  get lineProfile() {
    return this.$store.state.lineProfile
  }
  get currentUser() {
    return this.$store.state.currentUser
  }

  created() {
    this.$store.dispatch('getProfile')
    this.liffReady()
  }

  loadJournalCorners() {
    this.$store.dispatch('getJournalCorners', {
      journalCornerType: this.journalCornerType,
      params: this.filter
    })
  }

  getLiffId() {
    if (this.journalCornerType === 'cardiovascular') {
      return '1655606833-WJVMRebM'
    } else if (this.journalCornerType === 'cardiometabolic') {
      return '1655606833-3QPZkJBZ'
    } else if (this.journalCornerType === 'diabetes') {
      return '1655606833-rQX0yNk0'
    } else if (this.journalCornerType === 'covid19') {
      return '1655606833-n4gY1Q0Y'
    }
  }

  @Watch('currentUser')
  currentUserChanged(valueChange) {
    if (valueChange) {
      this.loadJournalCorners()
    } else {
      liff.init({ liffId: this.getLiffId() })
    }
  }
  @Watch('lineProfile')
  lineProfileChanged(valueChange) {
    if (valueChange) {
      const decodedIDToken = liff.getDecodedIDToken()

      this.$store.dispatch('register', {
        auth: {
          uid: valueChange.userId,
          full_name: valueChange.displayName,
          avatar: valueChange.pictureUrl,
          email: decodedIDToken.email
        }
      })
    }
  }
  @Watch('registerResponse')
  registerResponseChanged(valueChanged) {
    if (valueChanged && valueChanged.merck_user && valueChanged.merck_user.accept_privacy_policy) {
      this.loadJournalCorners()
    } else {
      this.$turbolinks("/liff/login")
    }
  }
}
