
import { Component, Vue } from 'vue-property-decorator';
import liff from "@line/liff";

@Component
export default class GlobalMixin extends Vue {
  liffReady() {
    liff.ready.then(() => {
      if (liff.isLoggedIn()) {
        this.$store.dispatch('getLineProfile')
      } else {
        liff.login()
      }
    })
  }
}
