
import {Vue, Component, Watch, Prop, Mixins} from "vue-property-decorator";
import GlobalMixin from "mixin/global_mixin.vue";
import liff from "@line/liff";

@Component
export default class Library extends Mixins(GlobalMixin) {
  @Prop() libraryType: string

  filter = {
    search: null
  }

  get libraries() {
    return this.$store.state.libraries
  }
  get registerResponse() {
    return this.$store.state.registerResponse
  }
  get lineProfile() {
    return this.$store.state.lineProfile
  }
  get currentUser() {
    return this.$store.state.currentUser
  }

  created() {
    this.$store.dispatch('getProfile')
    this.liffReady()
  }

  loadLibraries() {
    this.$store.dispatch('getLibraries', {
      libraryType: this.libraryType,
      params: this.filter
    })
  }

  getLiffId() {
    if (this.libraryType === 'cardiovascular') {
      return '1655606833-2xB354D3'
    } else if (this.libraryType === 'diabetes') {
      return '1655606833-aJOXYD8X'
    }
  }

  @Watch('currentUser')
  currentUserChanged(valueChange) {
    if (valueChange) {
      this.loadLibraries()
    } else {
      liff.init({ liffId: this.getLiffId() })
    }
  }
  @Watch('lineProfile')
  lineProfileChanged(valueChange) {
    if (valueChange) {
      const decodedIDToken = liff.getDecodedIDToken()

      this.$store.dispatch('register', {
        auth: {
          uid: valueChange.userId,
          full_name: valueChange.displayName,
          avatar: valueChange.pictureUrl,
          email: decodedIDToken.email
        }
      })
    }
  }
  @Watch('registerResponse')
  registerResponseChanged(valueChanged) {
    if (valueChanged && valueChanged.merck_user && valueChanged.merck_user.accept_privacy_policy) {
      this.loadLibraries()
    } else {
      this.$turbolinks("/liff/login")
    }
  }
}
