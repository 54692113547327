import Vue from "vue"
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"

Vue.use(Vuetify)

const opts = {
	theme: {
		themes: {
			light: {
				primary: "#0f69af",
				primaryDark: "#001F53",
				secondary: "#ffc12c",
				danger: "#e61e50",
			},
		},
	},
}

export default new Vuetify(opts)
