export default (httpClient) => ({
    async updateMerckUser({ commit }, params = {}) {
        const response = await httpClient.request({
            url: "/liff/merck_users/update",
            method: "PUT",
            data: params,
        }).catch((error) => {
            commit("updateMerckUserMutation", error.response.data);
            return
        });

        commit("updateMerckUserMutation", response.data);
    },
})
