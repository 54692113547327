export default {
    registerMutation(state, data) {
        state.registerResponse = data
    },
    signInMutation(state, data) {
        state.signInResponse = data
    },
    getProfileMutation(state, data) {
        state.currentUser = data
    }
}
