export default (httpClient) => ({
    async register({ commit }, params = {}) {
        const response = await httpClient.request({
            url: "/liff/auth/register",
            method: "POST",
            data: params,
        }).catch((error) => {
            commit("registerMutation", error.response.data);
            return
        });

        commit("registerMutation", response.data);
    },
    async signIn({ commit }, params = {}) {
        const response = await httpClient.request({
            url: "/liff/auth/login",
            method: "POST",
            data: params,
        }).catch((error) => {
            commit("signInMutation", error.response.data);
            return
        });

        commit("signInMutation", response.data);
    },
    async getProfile({ commit }, params = {}) {
        const response = await httpClient.request({
            url: "/liff/auth/profile",
            method: "GET",
        }).catch((error) => {
            commit("getProfileMutation", error.response.data);
            return
        });

        commit("getProfileMutation", response.data);
    },
})
