
import {Component, Watch, Mixins} from "vue-property-decorator";
import GlobalMixin from "mixin/global_mixin.vue";
import liff from "@line/liff";

@Component
export default class Login extends Mixins(GlobalMixin) {
  model = {
    username: null,
    password: null
  }
  loginLoading = false

  get signInResponse() {
    return this.$store.state.signInResponse
  }
  get lineProfile() {
    return this.$store.state.lineProfile
  }
  get currentUser() {
    return this.$store.state.currentUser
  }

  created() {
    this.$store.dispatch('getProfile')
    this.liffReady()
  }

  signIn() {
    this.loginLoading = true
    this.$store.dispatch('signIn', {auth: this.model})
  }

  @Watch('currentUser')
  currentUserChanged(valueChange) {
    if (valueChange && valueChange.merck_user) {
      this.$turbolinks("/liff/privacy-policy")
    } else {
      liff.init({ liffId: "1655606833-YkwbNMZb" })
    }
  }
  @Watch('signInResponse')
  signInResponseChanged(valueChange) {
    this.loginLoading = false
    if (valueChange && valueChange.code == 200) {
      this.$turbolinks("/liff/privacy-policy")
    }
  }
  @Watch('lineProfile')
  lineProfileChanged(valueChange) {
    if (valueChange) {
      const decodedIDToken = liff.getDecodedIDToken()

      this.$store.dispatch('register', {
        auth: {
          uid: valueChange.userId,
          full_name: valueChange.displayName,
          avatar: valueChange.pictureUrl,
          email: decodedIDToken.email
        }
      })
    }
  }
}
