import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
import { HTTPClient } from "api/HTTPClient";
import { getField, updateField } from "vuex-map-fields";

import AuthAction from "./actions/auth_action";
import AuthMutation from "./mutations/auth_mutation";
import AuthState from "./state/auth_state";

import LiffAction from "./actions/liff_action";
import LiffMutation from "./mutations/liff_mutation";
import LiffState from "./state/liff_state";

import MerckUserAction from "./actions/merck_user_action";
import MerckUserMutation from "./mutations/merck_user_mutation";
import MerckUserState from "./state/merck_user_state";

import LibraryAction from "./actions/library_action";
import LibraryMutation from "./mutations/library_mutation";
import LibraryState from "./state/library_state";

import JournalCornerAction from "./actions/journal_corner_action";
import JournalCornerMutation from "./mutations/journal_corner_mutation";
import JournalCornerState from "./state/journal_corner_state";

import WebinarCalendarAction from "./actions/webinar_calendar_action";
import WebinarCalendarMutation from "./mutations/webinar_calendar_mutation";
import WebinarCalendarState from "./state/webinar_calendar_state";

const httpClient = new HTTPClient();

Vue.use(Vuex)

const store = new Vuex.Store({
  getters: {
    getField,
  },
  state: {
    ...AuthState(),
    ...LiffState(),
    ...MerckUserState(),
    ...LibraryState(),
    ...JournalCornerState(),
    ...WebinarCalendarState(),
  },
  mutations: {
    updateField,
    ...AuthMutation,
    ...LiffMutation,
    ...MerckUserMutation,
    ...LibraryMutation,
    ...JournalCornerMutation,
    ...WebinarCalendarMutation,
  },
  actions: {
    ...AuthAction(httpClient),
    ...LiffAction(httpClient),
    ...MerckUserAction(httpClient),
    ...LibraryAction(httpClient),
    ...JournalCornerAction(httpClient),
    ...WebinarCalendarAction(httpClient),
  }
});

export default store;
