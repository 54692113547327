export default (httpClient) => ({
    async getJournalCorners({ commit }, options) {
        const { journalCornerType, params } = options

        const response = await httpClient.request({
            url: `/liff/journal_corners/${journalCornerType}.json`,
            method: "GET",
            params: params,
        })

        commit("getJournalCornersMutation", response.data);
    },
})
