
import { Vue, Component, Watch, Mixins } from "vue-property-decorator";
import GlobalMixin from "mixin/global_mixin.vue";
import liff from "@line/liff";

@Component
export default class WebinarCalendar  extends Mixins(GlobalMixin) {
  get lineProfile() {
    return this.$store.state.lineProfile
  }
  get registerResponse() {
    return this.$store.state.registerResponse
  }
  get currentUser() {
    return this.$store.state.currentUser
  }

  created() {
    this.$store.dispatch('getProfile')
    this.liffReady()
  }

  get allWebinarCalendars() {
    return this.$store.state.webinarCalendars
  }
  get bannerWebinarCalendar() {
    if (this.allWebinarCalendars.length > 0) {
      return this.allWebinarCalendars[0]
    } else {
      return {}
    }
  }
  get webinarCalendars() {
    if (this.allWebinarCalendars.length > 0) {
      return this.allWebinarCalendars.splice(1)
    } else {
      return []
    }
  }

  loadWebinarCalendars() {
    this.$store.dispatch("getWebinarCalendars")
  }

  openLink(link) {
    liff.openWindow({
      url: link,
      external: true
    });
  }

  @Watch('currentUser')
  currentUserChanged(valueChange) {
    if (valueChange) {
      this.loadWebinarCalendars()
    } else {
      liff.init({ liffId: '1655606833-w01ljr0l' })
    }
  }
  @Watch('lineProfile')
  lineProfileChanged(valueChange) {
    if (valueChange) {
      const decodedIDToken = liff.getDecodedIDToken()

      this.$store.dispatch('register', {
        auth: {
          uid: valueChange.userId,
          full_name: valueChange.displayName,
          avatar: valueChange.pictureUrl,
          email: decodedIDToken.email
        }
      })
    }
  }
  @Watch('registerResponse')
  registerResponseChanged(valueChanged) {
    if (valueChanged && valueChanged.merck_user && valueChanged.merck_user.accept_privacy_policy) {
      this.loadWebinarCalendars()
    } else {
      this.$turbolinks("/liff/login")
    }
  }
}
