export default (httpClient) => ({
    async getLibraries({ commit }, options) {
        const { libraryType, params } = options

        const response = await httpClient.request({
            url: `/liff/libraries/${libraryType}.json`,
            method: "GET",
            params: params,
        })

        commit("getLibrariesMutation", response.data);
    },
})
