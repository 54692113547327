export default (httpClient) => ({
    async getWebinarCalendars({ commit }, options = {}) {
        const response = await httpClient.request({
            url: `/liff/webinar_calendars.json`,
            method: "GET",
        })

        commit("getWebinarCalendarsMutation", response.data);
    },
})
