import Vue from "vue/dist/vue.esm";
import TurbolinksAdapter from "vue-turbolinks";

import store from "store";
import vuetify from "../plugins/vuetify";
import TurbolinksCustom from "../utilities/tubolinks-custom";
import GlobalMixin from '../mixin/global_mixin'

import Login from  "../pages/login";
import PrivacyPolicy from  "../pages/privacy_policy";
import Library from  "../pages/library";
import JournalCorner from  "../pages/journal_corner";
import WebinarCalendar from  "../pages/webinar_calendar";

Vue.prototype.$turbolinks = TurbolinksCustom;
Vue.use(TurbolinksAdapter);
Vue.use(require('vue-moment'));

Vue.mixin(GlobalMixin)

document.addEventListener("turbolinks:load", () => {
  new Vue({
    el: '[data-behavior="vue"]',
    store,
    vuetify,
    components: {
      Login,
      PrivacyPolicy,
      Library,
      JournalCorner,
      WebinarCalendar,
    },
  });
});
